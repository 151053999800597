import * as React from "react"
import { Helmet } from "react-helmet"

import Seo from "../components/seo"
import Layout from "../components/Layout/Layout"
import Hero from "../components/Hero/Hero"

const IndexPage = () => {
return (

<Layout>
    <Helmet>
    <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled"/>
    </Helmet>    
    <Seo title="Domirent" />
    <Hero/>
     
</Layout>


)
}
export default IndexPage;
